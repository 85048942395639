import $ from 'jquery'
import { auth } from './firebase'

// Wenn der Login Button gedrückt wurde
$('#loginBtn').on('click', event => {
  event.preventDefault()

  // Hole den Inhalt der Input Felder
  const email = $('#email').val()
  const password = $('#password').val()

  // Logge den Nutzer mit email und password ein.
  // Wenn es zu einem Fehler kommt wird dieser mit console.error ausgegeben
  auth.signInWithEmailAndPassword(email, password).catch(err => console.error(err))

  // Lösche den Inhalt der Input Felder
  $('#email').html('')
  $('#password').html('')
})

// Wenn der Button mit der ID logoutBtn gedrückt wird.
$('#logoutBtn').on('click', () => {
  auth.signOut().catch(err => console.error(err))
})

// Wenn Firebase eine Änderung feststellt (Nutzer hat sich eingeloggt oder ausgeloggt)
// wird diese Funktion aufgerufen
// Der selbe Befehl wird in weather-stations.js auch nochmal aufgerufen.
auth.onAuthStateChanged(user => {
  if (user) {
    // Nutzer ist eingeloggt
    console.log(`User logged in: ${user.email}`)

    // Verstecke alle Login Felder
    $('#email').hide()
    $('#password').hide()
    $('#loginBtn').hide()

    $('#logoutBtn').show()
    $('#userLabel').html(user.email)
  } else {
    // Nutzer ist ausgeloggt
    console.log('No user logged in')

    $('#email').show()
    $('#password').show()
    $('#loginBtn').show()

    $('#logoutBtn').hide()
    $('#userLabel').html('')
  }
})
